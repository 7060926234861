<template>
  <div class="py-5 bg-white form-md rounded-2 shadow-sm mb-5">
    <div
      class="alert-box"
      :blnalert="blnAltStatus || blnAltStatusunsucess"
      :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
    >
      <AltStatus
        :blnalert="blnAltStatus"
        icon="CorrectFill"
        coloricon="fill-success"
        class="success"
        :name="$t(strSMSGsuccess)"
      />
      <AltStatus
        :blnalert="blnAltStatusunsucess"
        icon="IncorrectFill"
        coloricon="fill-danger"
        class="error"
        :name="$t('174')"
        @mousemove="hover = true"
        @mouseleave="hover = false"
      />
    </div>
    <Form
      :typeSchema="typeSchema"
      :data="formdata"
      @onInput="openform($event)"
      @Insert="fetchInsertCmsSystemWord($event)"
      @Update="fetchUpdateCmsSystemWord($event)"
      :action="action"
      :request="request"
      ref="form"
    />
    <div class="row px-5 pb-0">
      <label class="fnt-regular fnt-subtitle">{{ name }} </label>
      <span class="pt-3 fnt-regular fnt-body">
        <span class="fnt-medium fnt-body">System link</span> คือ
        การบ่งบอกว่าคำนี้กำลังใช้งานอยู่ในมอดูลใดบ้าง ประกอบด้วย 4 ส่วน คือ
        <br />
        1) ตัวอักษร 3 ตัวแรกเป็นชื่อย่อของมอดูล เช่น ADS คือ Admin System, CMS
        คือ Curriculum Management System เป็นต้น <br />
        2) เครื่องหมายโคลอน (:) <br />
        3) ตัวอักษรย่อของฟังก์ชั่นงาน เช่น CP คือ Campus, PO คือ Position
        เป็นต้น <br />
        4) ตัวเลขของฟังก์ชั่นงาน ได้แก่ <br />
        01 คือ Preview, 02 คือ Insert, 03 คือ Edit และ 04 คือ Delete
      </span>
      <span class="fnt-regular fnt-body pt-3">
        <span class="fnt-medium fnt-body">หมายเหตุ</span><br />
        การกรอก System link จะมีลักษณะพิเศษอยู่ 2 แบบ คือ <br />
        1) ADS:All หมายความว่า คำนี้จะใช้กับทุกฟังก์ชั่นงานที่อยู่ภายในมอดูล ADS
        <br />
        2) All หมายความว่า คำนี้จะใช้กับทุกมอดูลของ CesSystem <br />
      </span>
      <div class="row px-4">
        <table class="table" aria-describedby="tableRightSystemword">
          <thead>
            <tr>
              <!-- ชื่อส่วนหัวตาราง -->
              <th
                v-for="head in heads"
                :key="head.id"
                scope="col"
                class="pt-3 fnt-body fnt-bold text-black"
              >
                {{ head.name }}
              </th>
            </tr>
          </thead>
          <tbody class="border-0">
            <tr class="border-bottom">
              <td>Identifiable</td>
              <td>การระบุตัวตน</td>
              <td>ADS:UF01</td>
              <td>ส่วนหัวตาราง</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/form/formsystemword.vue";
import {
  typeSchema,
  request,
} from "@/components/constants/schemaAddSystemWord";
import AltStatus from "@/components/alert/alertStatus.vue";
import axios from "axios";
// import urlapi from "@/typescript/urlapi/url";
export default {
  props: {
    name: { type: String },
  },
  components: {
    Form,
    AltStatus,
  },
  emits: ["reload"],
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCountHover++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  data() {
    return {
      heads: [
        { id: 1, name: "System word" },
        { id: 2, name: "System word (TH)" },
        { id: 3, name: "System link" },
        { id: 4, name: "Description" },
      ],
      ListDummy: {},
      blnOpenform: false,
      typeSchema: typeSchema,
      request: request,
      formdata: {},
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      hover: false, //hover alt
      strSMSGsuccess: "",
      timerEnabled: false,
      timerCount: 0,
      timerCountHover: 0,
      systemwordID: "",
    };
  },
  methods: {
    //เปิดฟอร์ม
    openform(state) {
      this.formdata = [];
      this.$refs.form.asSignData(this.formdata);
      this.blnOpenform = state;
    },
    async fetchInsertCmsSystemWord(data) {
      await axios
        .post('https://cesapi.wu.ac.th/ads' + "ads/v1/api/Systemword/DuplicateSystemword", data)
        .then((res) => {
          if (res.data.message == "Success") {
            axios
              .post('https://cesapi.wu.ac.th/ads' + "ads/v1/api/Systemword/InsertSystemList", data)
              .then((res) => {
                if (res.data.message == "Success") {
                  this.blnAltStatus = true;
                  this.strSMSGsuccess = "27";
                } else {
                  this.blnAltStatusunsucess = true;
                }
              });
          } else {
            this.blnAltStatusunsucess = true;
          }
        });
    },
    async fetchUpdateCmsSystemWord(data) {
      await axios
        .post('https://cesapi.wu.ac.th/ads' + "/v1/api/Systemword/DuplicateSystemword", data)
        .then((res) => {
          if (res.data.message == "Success") {
            axios
              .put(
                'https://cesapi.wu.ac.th/ads' +
                  "/v1/api/Systemword/UpdateSystemList/" +
                  this.systemwordID,
                data
              )
              .then((res) => {
                if (res.data.message == "Success") {
                  if (res.data.message == "Success") {
                    this.blnAltStatus = true;
                    this.strSMSGsuccess = "27";
                  } else {
                    this.blnAltStatusunsucess = true;
                  }
                }
              });
          } else {
            this.blnAltStatusunsucess = true;
          }
        });
    },

    async fetchGetSystemWordByID(id) {
      this.systemwordID = id;
      await axios
        .get('https://cesapi.wu.ac.th/ads' + "/v1/api/Systemword/SystemListById/" + id)
        .then((res) => {
          this.formdata = res.data.data;
          this.action = "Update";
          this.$refs.form.asSignData(this.formdata);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>