<template>
  <Tab :op="tablist" />
  <div class="tab-content w-100" id="myTabContent">
    <div
      class="tab-pane"
      id="tab1"
      role="tabpanel"
      aria-labelledby="tab1-tab"
      :class="tablist.room.id == 1 ? 'show active' : ''"
    >
      <div class="row tm-fm">
        <div class="col-12">
          <Left ref="left" @edit="edit($event)" name="System word list" />
        </div>
      </div>
    </div>
    <div class="tab-pane" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
      <div class="row tm-fm">
        <div class="col-6">
          <Left ref="left" @edit="edit($event)" name="System word list" />
        </div>
        <div class="col-6">
          <Right
            ref="right"
            @reload="reload()"
            name="System word information"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@/components/tabs/test.vue";
import Left from "@/views/systemword/Left.vue";
import Right from "@/views/systemword/Right.vue";
// import Filter from "@/components/filter/filter.vue";
// import Header from "@/components/headerMenu/haeder.vue";

export default {
  components: {
    // Filter,
    // Header,
    Left,
    Right,
    Tab,
  },

  data() {
    return {
      blnOpenFilter: false,
      blnOpenform: false,

      activeFiltersSelected: [],
      listSystemWordFilter: [],
      tablist: {
        room: {
          id: 1,
          name: "System word",
          srtName: "tab1",
        },
        format: {
          id: 2,
          name: "Add system word",
          srtName: "tab2",
        },
      },
    };
  },

  methods: {
    reload() {
      this.$refs.left.processLoadingData();
    },
    edit(id) {
      this.$refs.right.fetchGetSystemWordByID(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tm-fm {
  height: calc(100vh - 260px) !important;
  // height: 100% !important;
  .col-6 {
    height: 100% !important;
    // height: calc(100vh - 260px) !important;
    position: relative;
  }
}
</style>
