<template>
  <!-- <div v-for="(items, index) in items" :key="index" class="btn-group">
    <button class="btn fnt-subtitle fnt-medium px-3">{{ items }}</button>
  </div> -->
  <ul
    class="nav nav-tabs border-0 d-flex justify-content-stretch"
    id="myTab"
    role="tablist"
  >
    <li
      class="nav-item"
      role="presentation"
      v-for="(tabs, index) in op"
      :key="index"
    >
      <button
        class="nav-link border-0"
        :class="tabs.id == 1 ? 'active' : ''"
        :id="tabs.srtName + '-tab'"
        data-bs-toggle="tab"
        :data-bs-target="'#' + tabs.srtName"
        type="button"
        role="tab"
        :aria-controls="tabs.srtName"
        :aria-selected="tabs.id == 1 ? 'true' : 'false'"
      >
        {{ $t(tabs.name) }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    op: { type: Object },
    // ops: { type: Object },
  },
  methods: {},
//   data() {
//     return {
//       tablist: {
//         group: {
//           id: 1,
//           name: "Groups",
//           srtName: "tab1",
//         },
//         field: {
//           id: 2,
//           name: "Fields",
//           srtName: "tab2",
//         },
//         fieldsetting: {
//           id: 3,
//           name: "Field setting",
//           srtName: "tab3",
//         },
//         feature: {
//           id: 4,
//           name: "Feature",
//           srtName: "tab4",
//         },
//       },
//     };
//   },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  height: 48px;
  &:hover {
    background-color: $white !important;
    color: $primary !important;
  }
}
.active {
  position: relative;
  color: $primary;
  &:after {
    content: "";
    width: 100%;
    height: 4px;
    background-color: $primary;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.nav-link {
  color: $black;
}
</style>
