<template>
  <div class="overlay-box" v-if="overlay">
    <div
      class="overlay-alertpop"
      @click="(overlay = !overlay), (blnAltHavedata = false)"
    >
      <AlertPopup
        v-if="blnAltHavedata == true"
        :altMsgBox="$t('216')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        @confirm="confirmAltPopup($event)"
        classIcon="text-secondary"
      />
    </div>
  </div>
  <form v-if="blnShowform == true" class="pb-4 pt-0 px-5 border-radius-lg">
    <DynamicRenderer
      :typeSchema="typeSchema"
      :data="new_data"
      @input="updateData($event)"
      @close="closeForm($event)"
      @post="addData()"
      ref="DynamicForm"
    />
  </form>
</template>

<script>
import DynamicRenderer from "../../components/Renderer/DynamicRenderer.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
export default {
  components: {
    DynamicRenderer,
    AlertPopup,
  },
  props: {
    styleDlg: { type: String },
    modelTitle: { type: String },
    modelIcon: { type: String },
    idModel: { type: String },
    fontColor: { type: String },
    inactive: {
      type: Array,
    },
    typeSchema: {
      type: Array,
    },

    request: {
      type: Array,
    },
    data: { type: Object },
    action: { type: String },
    msgalertDataDuplicate: { type: Object },
  },
  emits: ["onInput", "Insert", "Update"],
  data() {
    return {
      overlay: true,
      new_data: { type: Array },
      blnAltHavedata: false,
      blnShowform: true,
    };
  },
  created() {
    this.new_data = this.data;
    // console.log(this.data);
  },
  mounted() {
    if (this.inactive !== undefined && this.inactive !== null) {
      // console.log(this.$refs.DynamicForm);
      this.$refs.DynamicForm.componentsInactive(this.inactive);
    }
  },
  methods: {
    asSignData(data) {
      this.blnShowform = false;
      this.new_data = data;
      this.blnShowform = true;
      // console.log(this.new_data);
    },
    updateData({ state, value }) {
      this.new_data[state] = value;
    },

    addData() {
      if (this.action == "Insert") {
        if (this.detectDatainField() == true) {
          this.$emit("Insert", this.new_data);
        }
      } else if (this.action == "Update") {
        if (this.detectDatainField() == true) {
          this.$emit("Update", this.new_data);
        }
      }
    },
    //ปิดform รับจากปุ่ม close
    closeForm() {
      if (this.action == "Insert") {
        this.blnAltHavedata = false;

        if (this.new_data != null) {
          for (var item in this.new_data) {
            if (this.new_data[item].length > 0) {
              this.overlay = true;
              this.blnAltHavedata = true;
            }
          }
        }

        if (this.blnAltHavedata == false) {
          this.$emit("onInput", false);
        }
      } else {
        this.$emit("onInput", false);
      }
    },

    confirmAltPopup(state) {
      if (state == false) {
        this.blnAltHavedata = false;
      } else if (state == true) {
        this.blnAltHavedata = false;
        this.$emit("onInput", false);
      }
    },
    detectDatainField() {
      var count = 0;

      for (var item in this.request) {
        // console.log(this.new_data[this.request[item]]);
        if (
          this.new_data[this.request[item]] == "" ||
          this.new_data[this.request[item]] == null ||
          this.$refs.DynamicForm.validate(this.request[item]) == true
        ) {
          this.$refs.DynamicForm.alertRequest(this.request[item]);
          count += 1;
        }
      }
      // console.log("Count  :" + count);
      if (count > 0) {
        return false;
      } else if (count == 0) {
        return true;
      }
    },

    detectalertDataDuplicate() {
      if (Array.isArray(this.msgalertDataDuplicate)) {
        for (var item in this.msgalertDataDuplicate) {
          this.$refs.DynamicForm.alertDataDuplicate(
            this.msgalertDataDuplicate[item].name,
            this.msgalertDataDuplicate[item].msg
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.admin-form-campus {
  max-width: 809px !important;
}
</style>
