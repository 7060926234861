export const typeSchema = [
  {
    group: [
      {
        group: [
          {
            class: "row",
            group: [
              {
                class: "row",
                group: [
                  {
                    class: "d-flex justify-content-between mb-4",
                    component: [
                      {
                        type: "HeadLabel",
                        label: "Add system word", //System word information
                        class: "fnt-regular fnt-subtitle",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-6 mb-3 pe-2",
                    component: [
                      {
                        label: "System word", // System word
                        type: "InputText",
                        datavalue: "strLDPEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpLDPEN",
                        validate: "letterEN",
                      },
                    ],
                  },
                  {
                    class: "col-6 mb-3 ps-2",

                    component: [
                      {
                        label: "System word (TH)", // System word
                        type: "InputText",
                        datavalue: "strLDPTH",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpLDPTH",
                        validate: "letterTH",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-12 mb-3",

                    component: [
                      {
                        label: "System link", // Description
                        labelSub: ": (e.g. ADS:UF01, ADS:All, All)",
                        type: "InputText",
                        datavalue: "strSystemlink",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpSystemlink",

                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-12 mb-3",

                    component: [
                      {
                        label: "Description", // Description
                        type: "InputLongText",
                        datavalue: "description",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpDescription",

                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    group: [
      {
        class:
          "border-bottom pb-4 modal-footer border-0 justify-content-start p-0 mt-3",
        component: [
          {
            type: "Button",
            name: "36", // OK
            state: "submit",
            class: "btn-fix me-3",
          },
          {
            type: "Button",
            name: "35", // Cancel
            value: false,
            state: "cancel",
            class: "btn-fix btn-cancel",
          },
        ],
      },
    ],
  },
];
export const request = [
  "strLDPEN",
  "strLDPTH",
  "strSystemlink",
];
